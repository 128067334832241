import React, { useContext } from 'react';
import { useCart } from 'react-use-cart';

import CheckoutItem from './CheckoutItem';
import CheckoutContext from '../context/Checkout';

function CheckoutItemList() {
  const { items, cartTotal } = useCart();
  const { orderTotal } = useContext(CheckoutContext);

  const formatValue = (value) =>
    new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(value);

  // const freeDelivery = (cartTotal) => {
  //   if (cartTotal >= 80) {
  //     return <span>Free</span>;
  //   } else {
  //     return <span>{formatValue(5)}</span>;
  //   }
  // };

  return (
    <div className="bg-white border border-primary text-primary sm:-mb-px">
      {items.map(CheckoutItem)}
      <div className="flex items-center justify-between px-6 py-2 mt-8">
        <span className="text-sm">sub total</span>
        <span className="font-semibold">{formatValue(cartTotal)}</span>
      </div>
      <div className="flex items-center justify-between px-6 py-2">
        <span className="text-sm">shipping</span>
        <span className="font-semibold">
          {/* {cartTotal ? freeDelivery(cartTotal) : '-'} */}
          on the house
        </span>
      </div>
      <div className="flex items-center justify-between px-6 py-8">
        <span className="text-lg font-semibold">total</span>
        <span className="text-xl font-bold">{formatValue(orderTotal)}</span>
      </div>
    </div>
  );
}

export default CheckoutItemList;
