import React, { useState, useEffect, useContext } from 'react';
import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { useMutation } from 'graphql-hooks';
import CheckoutContext from '../../context/Checkout';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import { useCart } from 'react-use-cart';
import get from 'lodash/get';

const CHECKOUT_MUTATION = `mutation checkout($input: CheckoutInput!) {
  checkout(input: $input) {
    graphCMSOrderId
    
  }
}`;

const PAYMENT_INTENT_MUTATION = `mutation createPaymentIntent($input: PaymentIntentInput!) {
  createPaymentIntent(input: $input) {
    id
    clientSecret
    status
  }
}`;

const ORDER_RECEIVED_EMAIL_SEND_MUTATION = `mutation orderReceivedEmailSendMutation($input: orderReceivedEmailSendInput!){
   orderReceivedEmailSendMutation(input: $input) {
      isEmailSent
    }
}`;

const getServerShippingDetails = (userShippingAddress) => {
  const shippingAddress = {
    // TODO:: Change the demo address in case of address not found
    address1: get(userShippingAddress, 'addressLine.0', 'Address not found'),
    address2: get(userShippingAddress, 'addressLine.1', ''),
    city: userShippingAddress.city || '',
    country: userShippingAddress.country || '',
    name: '',
    state: userShippingAddress.region || '',
    zip: userShippingAddress.postalCode || '',
  };
  return shippingAddress;
};
const CheckoutButton = () => {
  const stripe = useStripe();
  const { emptyCart, cartTotal, items } = useCart();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [userShippingAddress, setUserShippingAddress] = useState(null);

  const [checkout] = useMutation(CHECKOUT_MUTATION);

  // const [estimateOrderCosts] = useMutation(CALCULATE_MUTATION);
  const [createPaymentIntent] = useMutation(PAYMENT_INTENT_MUTATION);
  const [orderReceivedEmailSendMutation] = useMutation(
    ORDER_RECEIVED_EMAIL_SEND_MUTATION
  );
  const {
    orderTotal,
    checkoutSuccess,
    checkoutError,
    checkoutProcessing,
  } = useContext(CheckoutContext);

  const handleGetGraphCMS = async (input) => {
    const {
      data: {
        checkout: { graphCMSOrderId },
      },
    } = await checkout({
      variables: {
        input,
      },
    });

    return { graphCMSOrderId };
  };

  const handleCheckoutSuccess = (orderId) => {
    checkoutSuccess();

    emptyCart();

    navigate('success', { state: { orderId } });
  };

  const handleCheckoutError = ({
    message = 'Unable to process order. Please try again',
  }) => {
    checkoutError({ message });

    toast.error(message, {
      className: 'bg-red',
    });
  };

  const handleGetStripePaymentIntent = async (
    email,
    orderTotal,
    graphCMSOrderId
  ) => {
    const {
      data: {
        createPaymentIntent: { clientSecret },
      },
    } = await createPaymentIntent({
      variables: {
        input: {
          description: `deadnice order | ${graphCMSOrderId}`,
          email,
          metadata: { graphCMSOrderId },
          total: orderTotal,
        },
      },
    });

    return { clientSecret };
  };

  const handleGetDate = () => {
    const date = new Date();
    return `${('0' + date.getDate()).slice(-2)}-${(
      '0' +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  const handleSendOrderReceiveEmail = async (orderData) => {
    const {
      order_id,
      date,
      total,
      name,
      email,
      first_line,
      second_line,
      city,
      country,
      sub_total,
      shipping_cost,
      items,
      zip,
    } = orderData;

    try {
      return await orderReceivedEmailSendMutation({
        variables: {
          input: {
            order_id,
            date,
            total,
            name,
            email,
            first_line,
            second_line,
            city,
            country,
            sub_total,
            shipping_cost,
            items,
            zip
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: 'gbp',
        total: {
          label: 'Order',
          amount: orderTotal * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]); // orderTotal dep...

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', onPaymentMethod);
      paymentRequest.on('shippingaddresschange', onShippingAddressChange);
    }

    return () => {
      paymentRequest && paymentRequest.off('paymentmethod', onPaymentMethod);
      paymentRequest &&
        paymentRequest.off('shippingaddresschange', onShippingAddressChange);
    };
  }, [paymentRequest]);

  //==========================================
  const onPaymentMethod = async (ev) => {
    const stripeShippingAddress = get(
      ev,
      'shippingAddress',
      userShippingAddress
    );
    const { payerEmail, payerName, payerPhone } = ev;
    checkoutProcessing();
    try {
      const checkoutItems = items.map(
        ({ id: remoteId, description, image, ...rest }) => ({
          url: image.url,
          remoteId,
          ...rest,
        }),
      );

      const name = payerName || '';
      const email = payerEmail || '';
      const phone = payerPhone || '';
      const shippingAddress = getServerShippingDetails(stripeShippingAddress);
      shippingAddress.name = name;
      const input = {
        name,
        email,
        phone,
        total: orderTotal,
        items: checkoutItems,
        shippingAddress,
        billingAddress: shippingAddress,
      };

      const { graphCMSOrderId } = await handleGetGraphCMS(input);

      const { clientSecret } = await handleGetStripePaymentIntent(
        email,
        orderTotal,
        graphCMSOrderId
      );
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        ev.complete('fail');
      } else {
        ev.complete('success');
        if (paymentIntent.status === 'requires_action') {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            handleCheckoutError(error);
          } else {
            const orderData = {
              order_id: graphCMSOrderId,
              date: handleGetDate(),
              name,
              email,
              first_line: shippingAddress.address1,
              second_line: shippingAddress.address2,
              city: shippingAddress.city,
              country: shippingAddress.country,
              sub_total: cartTotal,
              shipping_cost: cartTotal >= 80 ? 0 : 5,
              items: checkoutItems,
              zip: shippingAddress.zip,
              total: orderTotal,
            };

            await handleSendOrderReceiveEmail(orderData);
            handleCheckoutSuccess(graphCMSOrderId);
          }
        } else {
          const orderData = {
            order_id: graphCMSOrderId,
            date: handleGetDate(),
            name,
            email,
            first_line: shippingAddress.address1,
            second_line: shippingAddress.address2,
            city: shippingAddress.city,
            country: shippingAddress.country,
            sub_total: cartTotal,
            shipping_cost: cartTotal >= 80 ? 0 : 5,
            items: checkoutItems,
            zip: shippingAddress.zip,
            total: orderTotal,
          };

          await handleSendOrderReceiveEmail(orderData);
          handleCheckoutSuccess(graphCMSOrderId);
        }
      }
    } catch (err) {
      handleCheckoutError(err);
    }
  };

  // calculate shipping
  // const calculateShippingCosts = async (shippingAddress) => {
  //   checkoutProcessing();
  //   try {
  //     const input = {
  //       shippingAddress,
  //       items: items.map(
  //         ({
  //           id: remoteId,
  //           description,
  //           price,
  //           image,
  //           itemTotal,
  //           files,
  //           ...rest
  //         }) => ({
  //           remoteId,
  //           itemTotal,
  //           files,
  //           ...rest,
  //         })
  //       ),
  //     };
  //     const {
  //       data: {
  //         estimateOrderCosts: { shippingRate, taxRate, vatRate },
  //       },
  //     } = await estimateOrderCosts({ variables: { input } });

  //     const shippingAmount = Math.round(shippingRate);
  //     updateShipping(shippingAmount);
  //     updateTax(Math.round(taxRate + vatRate));
  //     return shippingAmount;
  //   } catch (err) {
  //     handleCheckoutError(err);
  //   }
  // };

  // if you want to listen to shippingAddressChange
  const onShippingAddressChange = async (ev) => {
    const shippingAddress = get(ev, 'shippingAddress', {});
    console.log('Stripe event data', ev);
    setUserShippingAddress(shippingAddress);

    // todo:: change the details here
    const shippingOptions = [
      {
        id: 'standard-shipping',
        label: 'Standard Shipping',
        detail: 'Arrives in 3 to 7 days',
        amount: orderTotal * 100,
      },
    ];

    /*
    // if Address line length not received, then a demo address will be passed
    // TODO:: implement a logic in that case to change the address with a valid address
    if (shippingAddress.addressLine.length < 1) {
      shippingOptions[0].label = 'invalid shipping address';
      shippingOptions[0].amount = 0;

      ev.updateWith({
        status: 'invalid_shipping_address',
        shippingOptions: shippingOptions,
      });
      return;
    }
    */

    // orderTotal must be number
    if (orderTotal >= 80) {
      shippingOptions[0].label = 'Free Shipping';
      shippingOptions[0].amount = 0;
      ev.updateWith({
        status: 'success',
        shippingOptions: shippingOptions,
      });
      return;
    }

    // calculate shipping first
    try {
      // if required to calculate the shipping dynamically, uncomment this
      // const serverShippingAddress = getServerShippingDetails(shippingAddress);
      // const shippingCost = await calculateShippingCosts(serverShippingAddress);
      // shippingOptions[0].amount = Number(shippingCost || 0) * 100;

      // since orderTotal already includes the shipping amount, so setting it to zero
      shippingOptions[0].label = 'Shipping amount included in the order amount';
      shippingOptions[0].amount = 0;

      ev.updateWith({
        status: 'success',
        shippingOptions: shippingOptions,
      });
    } catch (err) {
      console.log(err);
      ev.updateWith({
        status: 'failed',
      });
    }
  };

  //===========================================

  if (paymentRequest) {
    const options = {
      paymentRequest,
      style: {
        paymentRequestButton: {
          type: 'default',
          // One of 'default', 'book', 'buy', or 'donate'
          // Defaults to 'default'

          theme: 'dark',
          // One of 'dark', 'light', or 'light-outline'
          // Defaults to 'dark'

          height: '55px',
          // Defaults to '40px'. The width is always '100%'.
        },
      },
    };
    return <PaymentRequestButtonElement options={options} />;
  }

  return <div className="checkout-button" />;
};

CheckoutButton.propTypes = {};

export default CheckoutButton;
