import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../Input';
import Select from '../Select';
import CountryList from './../CountryList';
import CheckoutContext from '../../context/Checkout';
function BillingForm() {
  const { errors, register } = useFormContext();
  const { allowPayment, processing: checkoutProcessing } =
    useContext(CheckoutContext);
  const disableInput = allowPayment || checkoutProcessing;
  return (
    <div className="-mt-px bg-white border border-primary">
      <h3 className="p-8 text-3xl font-bold text-primary md:text-4xl">
        billing
      </h3>
      <div className="-mt-px">
        <Input
          name="billing.name"
          placeholder="name"
          disabled={disableInput}
          register={register({ required: 'billing name is required' })}
          errors={errors}
        />
      </div>
      <div className="-mt-px">
        <Input
          name="billing.address1"
          placeholder="address line 1"
          disabled={disableInput}
          register={register({
            required: 'billing address line 1 is required',
          })}
          errors={errors}
        />
      </div>

      <div className="-mt-px">
        <Input
          name="billing.address2"
          placeholder="apartment, suite, etc. (optional)"
          disabled={disableInput}
          register={register}
          errors={errors}
        />
      </div>

      <div className="md:flex">
        <div className="-mt-px md:w-1/2">
          <Input
            name="billing.city"
            placeholder="city"
            disabled={disableInput}
            register={register({ required: 'billing city is required' })}
            errors={errors}
          />
        </div>
        <div className="-mt-px -ml-px md:w-1/2 sm:ml-0">
          <Select
            name="billing.country"
            disabled={disableInput}
            register={register({ required: 'billing country is required' })}
            options={CountryList.map((name) => ({
              name,
              value: name,
            }))}
            errors={errors}
          />
        </div>
      </div>
      <div className="md:flex">
        <div className="-mt-px md:w-1/2 ">
          <Input
            name="billing.state"
            placeholder="county / state"
            disabled={disableInput}
            register={register({ required: 'county / state  is required' })}
            errors={errors}
          />
        </div>
        <div className="-mt-px border-l-0 md:w-1/2 sm:border-l border-primary ">
          <Input
            name="billing.zip"
            placeholder="postcode / ZIP"
            disabled={disableInput}
            register={register({ required: 'billing ZIP is required' })}
            errors={errors}
          />
        </div>
      </div>
    </div>
  );
}
export default BillingForm;
