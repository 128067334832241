import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { useForm, FormProvider } from 'react-hook-form';
import { useMutation } from 'graphql-hooks';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useCart } from 'react-use-cart';
import { toast } from 'react-toastify';
import { handleGetDate } from '../../utils/date-helpers';

import BillingForm from './BillingForm';
import PaymentForm from './PaymentForm';
import ShippingForm from './ShippingForm';
import CheckoutContext from '../../context/Checkout';

// const CALCULATE_MUTATION = `mutation estimateOrderCosts($input: EstimateOrderCostsInput!) {
//   estimateOrderCosts(input: $input) {
//     currency
//     shippingRate
//     taxRate
//     vatRate
//   }
// }`;

const CHECKOUT_MUTATION = `mutation checkout($input: CheckoutInput!) {
  checkout(input: $input) {
    graphCMSOrderId
    
  }
}`;

const PAYMENT_INTENT_MUTATION = `mutation createPaymentIntent($input: PaymentIntentInput!) {
  createPaymentIntent(input: $input) {
    id
    clientSecret
    status
  }
}`;

const ORDER_RECEIVED_EMAIL_SEND_MUTATION = `mutation orderReceivedEmailSendMutation($input: orderReceivedEmailSendInput!){
   orderReceivedEmailSendMutation(input: $input) {
      isEmailSent
    }
}`;

const ORDER_DISPATCH_EMAIL_SEND_MUTATION = `mutation orderDispatchEmailSendMutation($input: orderDispatchEmailSendInput!){
   orderDispatchEmailSendMutation(input: $input) {
      isEmailSent
    }
}`;

function CheckoutForm() {
  const methods = useForm({
    defaultValues: {
      separateBilling: false,
    },
  });
  const { handleSubmit, watch, getValues } = methods;
  // const [estimateOrderCosts] = useMutation(CALCULATE_MUTATION);
  const [checkout] = useMutation(CHECKOUT_MUTATION);
  const [createPaymentIntent] = useMutation(PAYMENT_INTENT_MUTATION);
  const [orderReceivedEmailSendMutation] = useMutation(
    ORDER_RECEIVED_EMAIL_SEND_MUTATION
  );
  const [orderDispatchEmailSendMutation] = useMutation(
    ORDER_DISPATCH_EMAIL_SEND_MUTATION
  );
  const { emptyCart, items, cartTotal } = useCart();
  const { separateBilling } = watch();
  const {
    allowPayment,
    checkoutPayment,
    checkoutError,
    checkoutProcessing,
    checkoutSuccess,
    orderTotal,
  } = useContext(CheckoutContext);
  const stripe = useStripe();
  const elements = useElements();

  const useSeparateBilling = !!separateBilling;

  const handleCheckoutError = ({
    message = 'Unable to process order. Please try again',
  }) => {
    checkoutError({ message });

    toast.error(message, {
      className: 'bg-red',
    });
  };

  const handleCheckoutSuccess = (orderId) => {
    checkoutSuccess();

    emptyCart();

    navigate('success', { state: { orderId } });
  };

  const calculateOrderCosts = async (values) => {
    checkoutProcessing();
    try {
      const input = {
        shippingAddress: values.shipping,
        items: items.map(
          ({
            id: remoteId,
            description,
            image,
            itemTotal,
            files,
            ...rest
          }) => ({
            remoteId,
            itemTotal,
            files,
            ...rest,
          })
        ),
      };
      // const { data: { estimateOrderCosts: { shippingRate, taxRate, vatRate }, }, } = await estimateOrderCosts({ variables: { input } });

      // updateShipping(Math.round(shippingRate));
      // updateTax(Math.round(taxRate + vatRate));

      checkoutPayment();
    } catch (err) {
      handleCheckoutError(err);
    }
  };

  const handleSendOrderReceiveEmail = async (orderData) => {
    const {
      order_id,
      date,
      total,
      name,
      items,
      email,
      first_line,
      second_line,
      zip,
      city,
      country,
      sub_total,
      shipping_cost,
    } = orderData;

    try {
      return await orderReceivedEmailSendMutation({
        variables: {
          input: {
            order_id,
            date,
            total,
            name,
            email,
            items,
            first_line,
            second_line,
            zip,
            city,
            country,
            sub_total,
            shipping_cost,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetOrderData = () => {
    const values = getValues();
    const {
      email,
      phone,
      shipping: { name, ...rest },
      billing: billingAddress,
    } = values;

    const checkoutItems = items.map(
      ({ id: remoteId, description, name, image: { url }, ...rest }) => ({
        remoteId,
        name,
        url,
        ...rest,
      })
    );
    const shippingAddress = { name, ...rest };

    return {
      name,
      email,
      phone,
      orderTotal,
      shippingAddress,
      billingAddress,
      checkoutItems,
    };
  };

  const handleGetGraphCMS = async (input) => {
    const {
      data: {
        checkout: { graphCMSOrderId },
      },
    } = await checkout({
      variables: {
        input,
      },
    });

    return { graphCMSOrderId };
  };

  const handleGetStripePaymentIntent = async (
    email,
    orderTotal,
    graphCMSOrderId = new Date()
  ) => {
    const {
      data: {
        createPaymentIntent: { clientSecret },
      },
    } = await createPaymentIntent({
      variables: {
        input: {
          description: `deadnice order | ${graphCMSOrderId}`,
          email,
          metadata: { graphCMSOrderId },
          total: orderTotal,
        },
      },
    });

    return { clientSecret };
  };

  const submitOrder = async () => {
    checkoutProcessing();

    try {
      const {
        name,
        email,
        phone,
        orderTotal,
        shippingAddress,
        billingAddress,
        checkoutItems,
      } = handleGetOrderData();

      const input = {
        name,
        email,
        phone,
        total: orderTotal,
        shippingAddress,
        billingAddress: useSeparateBilling ? billingAddress : shippingAddress,
        items: checkoutItems,
      };

      const { clientSecret } = await handleGetStripePaymentIntent(
        email,
        orderTotal,
        graphCMSOrderId
      );

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement('card'),
        },
      });

      if (error) throw new Error(error.message);
      const { graphCMSOrderId } = await handleGetGraphCMS(input);

      const orderData = {
        order_id: graphCMSOrderId,
        date: handleGetDate(),
        total: orderTotal,
        name,
        email,
        items: checkoutItems,
        first_line: shippingAddress.address1,
        second_line: shippingAddress.address2,
        city: shippingAddress.city,
        zip: shippingAddress.zip.toUpperCase(),
        country: shippingAddress.country,
        sub_total: cartTotal,
        shipping_cost: cartTotal >= 80 ? 0 : 5,
      };

      await handleSendOrderReceiveEmail(orderData);

      handleCheckoutSuccess(graphCMSOrderId);
    } catch (err) {
      handleCheckoutError(err);
    }
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(
            allowPayment ? submitOrder : calculateOrderCosts
          )}
        >
          <ShippingForm />
          {useSeparateBilling && <BillingForm />}
          <PaymentForm />
          {/*Stripe checkout button*/}
        </form>
        {/*Paypal payment*/}
        {allowPayment && (
          <div style={{ maxWidth: '800px', margin: '20px auto 20px auto' }}>
            {/*<Paypal
              orderTotal={orderTotal}
              onPaypalPaymentSuccess={onPaypalPaymentSuccess}
              onPaypalPaymentError={onPaypalPaymentError}
            />*/}
          </div>
        )}
      </FormProvider>
    </>
  );
}

export default CheckoutForm;
