import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import Select from '../Select';
import CountryList from './../CountryList';
import LoadingSVG from '../../svg/loading.svg';

import Input from '../Input';
import Checkbox from '../Checkbox';
import CheckoutContext from '../../context/Checkout';

import useSound from 'use-sound';
import Switch from '../../sounds/switch.mp3';

function ShippingForm() {
  const [close] = useSound(Switch);
  const { errors, register } = useFormContext();
  const { allowPayment, processing: checkoutProcessing } = useContext(
    CheckoutContext
  );

  // const { shipping: { country: shippingCountryCode } = {} } = watch({
  //   nest: true,
  // });

  // const activeShippingCountry = shippingCountries.find(
  //   (country) => country.code === shippingCountryCode
  // );

  const disableInput = allowPayment || checkoutProcessing;

  return (
    <div className="bg-white border border-primary">
      <h3 className="text-primary text-3xl md:text-4xl p-8 font-bold">
        delivery
      </h3>

      <div className="-mb-px">
        <Input
          name="shipping.name"
          placeholder="name"
          disabled={disableInput}
          register={register({ required: 'shipping name is required' })}
          errors={errors}
        />
      </div>

      <div className="md:flex">
        <div className="md:w-1/2 border-r-0 -mb-px border-primary md:border-r">
          <Input
            name="email"
            type="email"
            placeholder="email address"
            disabled={disableInput}
            register={register({
              required: 'email is required',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'email is invalid',
              },
            })}
            errors={errors}
          />
        </div>

        <div className="md:w-1/2 -mb-px">
          <Input
            name="phone"
            type="tel"
            placeholder="contact no."
            disabled={disableInput}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      <div className="-mb-px">
        <Input
          name="shipping.address1"
          placeholder="address line 1"
          disabled={disableInput}
          register={register({
            required: 'Shipping address line 1 is required',
          })}
          errors={errors}
        />
      </div>

      <div className="-mb-px">
        <Input
          name="shipping.address2"
          placeholder="apartment, suite, etc. (optional)"
          disabled={disableInput}
          register={register}
          errors={errors}
        />
      </div>

      <div className="md:flex -mx-px border-l border-r border-primary -mb-px">
        <div className="md:w-1/2">
          <Input
            name="shipping.city"
            placeholder="city"
            disabled={disableInput}
            register={register({ required: 'shipping city is required' })}
            errors={errors}
          />
        </div>

        <div className="md:w-1/2 -mt-px -ml-px md:ml-0 md:mt-0">
          <Select
            name="shipping.country"
            disabled={disableInput}
            register={register({ required: 'shipping ZIP is required' })}
            options={CountryList.map((name) => ({
              value: name,
              name,
            }))}
            errors={errors}
          />
        </div>
      </div>

      <div className="md:w-full border-primary">
        <Input
          name="shipping.zip"
          placeholder="postcode / ZIP"
          disabled={disableInput}
          register={register({
            required: 'shipping postcode / ZIP is required',
          })}
          errors={errors}
        />
      </div>

      {!allowPayment && (
        <div className="flex flex-col items-center mt-5 justify-between">
          <Checkbox
            name="separateBilling"
            disabled={disableInput}
            register={register}
          >
            use different billing address
          </Checkbox>
          <button
            onClick={close}
            type="submit"
            className={classnames('primary-btn h-20 w-full mt-5', {
              'cursor-not-allowed opacity-50': disableInput,
            })}
            disabled={disableInput}
          >
            {checkoutProcessing ? (
              <div className="flex flex-col items-center">
                <LoadingSVG />
              </div>
            ) : (
              'next'
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default ShippingForm;
