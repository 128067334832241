import React from 'react';
import { PayPalButton } from 'react-paypal-button-v2';

// const sandboxId =
//   'ART1ddKaFbbrvn_akJTh703-6gZ0NpwL4D3UxsV0h09vkJ-2dcuZ1d2--WJZ1EKT8MRhWgTsLlO4DTUv';
// sb-lopoz3623156@personal.example.com
// Password1

// const liveId =
//   'AYd7MNggwOX0IBPMCY6XaBOSiOLfzKmYYJn11M1rObsywgO-mjA_1IOK920jskpJJCGxiarCV7YvrNU7';

const Paypal = ({
  orderTotal,
  onPaypalPaymentSuccess,
  onPaypalPaymentError,
}) => {
  return (
    <div>
      <PayPalButton
        currency="GBP"
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency: 'GBP',
                  value: String(orderTotal),
                },
              },
            ],
          });
        }}
        options={{
          clientId:
            'AYd7MNggwOX0IBPMCY6XaBOSiOLfzKmYYJn11M1rObsywgO-mjA_1IOK920jskpJJCGxiarCV7YvrNU7',
          currency: 'GBP',
        }}
        onSuccess={onPaypalPaymentSuccess}
        onError={onPaypalPaymentError}
        style={{
          shape: 'rect',
          color: 'silver',
          layout: 'horizontal',
          tagline: 'false',
        }}
      />
    </div>
  );
};

export default Paypal;
