import React, { useContext } from 'react';
import { useMutation } from 'graphql-hooks';
import CheckoutContext from '../../context/Checkout';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import { useCart } from 'react-use-cart';
import Paypal from '../CheckoutForm/PaypalBtn';
import { handleGetDate } from '../../utils/date-helpers';
import get from 'lodash/get';

const CHECKOUT_MUTATION = `mutation checkout($input: CheckoutInput!) {
  checkout(input: $input) {
    graphCMSOrderId
    
  }
}`;

const ORDER_RECEIVED_EMAIL_SEND_MUTATION = `mutation orderReceivedEmailSendMutation($input: orderReceivedEmailSendInput!){
   orderReceivedEmailSendMutation(input: $input) {
      isEmailSent
    }
}`;

const PaypalCheckoutButton = () => {
  const { emptyCart, cartTotal, items } = useCart();
  const [checkout] = useMutation(CHECKOUT_MUTATION);
  const [orderReceivedEmailSendMutation] = useMutation(
    ORDER_RECEIVED_EMAIL_SEND_MUTATION,
  );
  const {
    orderTotal,
    checkoutSuccess,
    checkoutError,
    checkoutProcessing,
  } = useContext(CheckoutContext);

  const handleGetOrderData = () => {
    const checkoutItems = items.map(
      ({ id: remoteId, description, image, ...rest }) => ({
        url: image.url,
        remoteId,
        ...rest,
      }),
    );

    return {
      orderTotal,
      checkoutItems,
    };
  };

  const handleGetGraphCMSAndPrintfulOrderId = async (input) => {
    const {
      data: {
        checkout: { graphCMSOrderId },
      },
    } = await checkout({
      variables: {
        input,
      },
    });
    return { graphCMSOrderId };
  };

  const handleSendOrderReceiveEmail = async (orderData) => {
    const {
      order_id,
      date,
      total,
      name,
      email,
      first_line,
      second_line,
      city,
      country,
      sub_total,
      shipping_cost,
      items,
      zip
    } = orderData;

    try {
      return await orderReceivedEmailSendMutation({
        variables: {
          input: {
            order_id,
            date,
            total,
            name,
            email,
            first_line,
            second_line,
            city,
            country,
            sub_total,
            shipping_cost,
            items,
            zip
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleCheckoutSuccess = (orderId) => {
    checkoutSuccess();

    emptyCart();

    navigate('success', { state: { orderId } });
  };

  const handleCheckoutError = ({
                                 message = 'Unable to process order. Please try again',
                               }) => {
    checkoutError({ message });

    toast.error(message, {
      className: 'bg-red',
    });
  };

  // Function gets called when payment through paypal is successful
  const onPaypalPaymentSuccess = async (payerData) => {
    checkoutProcessing();
    try {
      // form
      const { orderTotal, checkoutItems } = handleGetOrderData();

      // paypal information
      const { name, email, phone, shippingAddress } = getPaypalUserData(
        payerData,
      );

      const input = {
        name,
        email,
        phone,
        total: orderTotal,
        shippingAddress,
        billingAddress: shippingAddress,
        items: checkoutItems,
      };

      const { graphCMSOrderId } = await handleGetGraphCMSAndPrintfulOrderId(
        input
      );

      const orderData = {
        order_id: graphCMSOrderId,
        date: handleGetDate(),
        total: orderTotal,
        name,
        email,
        first_line: shippingAddress.address1,
        second_line: shippingAddress.address2,
        city: shippingAddress.city,
        country: shippingAddress.country,
        sub_total: cartTotal,
        shipping_cost: cartTotal >= 80 ? 0 : cartTotal,
        items: checkoutItems,
        zip: shippingAddress.zip
      };

      await handleSendOrderReceiveEmail(orderData);

      handleCheckoutSuccess(graphCMSOrderId);
    } catch (err) {
      console.error(err);
      handleCheckoutError(err);
    }
  };

  // Extract required data from Paypal's payerData object
  const getPaypalUserData = (payerData) => {
    const shipping = get(payerData, 'purchase_units.0.shipping', {});
    const payer = get(payerData, 'payer', {});
    const returnData = {
      name: get(shipping, 'name.full_name', '-'),
      email: get(payer, 'email_address', ''),
      phone: '',
      shippingAddress: {
        address1: get(shipping, 'address.address_line_1', ''),
        address2: get(shipping, 'address.address_line_2', ''),
        state: get(shipping, 'address.admin_area_1', ''),
        city: get(shipping, 'address.admin_area_2', ''),
        country: get(shipping, 'address.country_code', ''),
        name: get(shipping, 'name.full_name', '-'),
        zip: get(shipping, 'address.postal_code', ''),
      },
    };
    return returnData;
  };

  // Function gets called payment through paypal has some kind of error.
  const onPaypalPaymentError = () => {
    toast.error('Something went wrong');
  };

  return (
    <Paypal
      orderTotal={orderTotal}
      onPaypalPaymentSuccess={onPaypalPaymentSuccess}
      onPaypalPaymentError={onPaypalPaymentError}
    />
  );

  // return <div className='checkout-button' />;
};

PaypalCheckoutButton.propTypes = {};

export default PaypalCheckoutButton;
