import React from 'react';
import Seo from '../components/SEO';
import Checkout from '../components/Checkout';
import X from '../svg/x.svg';
import useSound from 'use-sound';
import Pop from '../sounds/pop.mp3';

function CheckoutPage() {
  const [pop] = useSound(Pop);
  const historyBack = () => {
    window.history.back();
  };

  return (
    <React.Fragment>
      <Seo pageTitle="checkout" />
      <div className="flex justify-between px-4">
        <h1 className="text-4xl font-bold md:text-6xl text-primary">
          checkout
        </h1>
        <button
          onClick={() => {
            historyBack();
            pop();
          }}
          onKeyDown={() => {
            historyBack();
            pop();
          }}
          className="text-primary"
        >
          <X className="hover:opacity-50" />
        </button>
      </div>
      <Checkout />
    </React.Fragment>
  );
}

export default CheckoutPage;
