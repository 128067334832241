import React from 'react';
import Lock from '../../svg/lock.svg';

function PaymentInfo() {
  return (
    <div className="p-8 -mb-px border border-l-0 border-r-0 border-primary text-primary b-3">
      <div className="flex flex-row items-center justify-between">
        <p className="text-sm leading-relaxed">
          secure payment protected with 256-bit encryption
        </p>
        <Lock />
      </div>
      {/* <br />
      <br />
      <hr />
      <br />
      <br />
      <p className="text-sm leading-relaxed text-primary">
        This is a <strong>test checkout</strong>. You can simulate transactions
        using any valid expiry date, CVC code and{' '}
        <code className="font-bold text-primary">4242 4242 4242 4242</code>, or{' '}
        <code className="font-bold text-primary">4000 0000 0000 3220</code> if
        you want trigger 3D Secure 2 authentication.
      </p> */}
    </div>
  );
}

export default PaymentInfo;
