import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

function CheckoutItem({ id, name, quantity, price, image, size }) {
  const total = quantity * price;
  const formattedLineTotal = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  }).format(total);
  return (
    <div
      key={id}
      className="flex items-center px-4 py-8 border-b border-primary"
    >
      <div className="relative mr-4">
        <GatsbyImage
          className="w-16 h-16 md:h-20 md:w-20"
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={name}
          title={name}
        />
        <div className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 -mt-1 -mr-1 text-xs bg-white border text-primary border-primary">
          {quantity}
        </div>
      </div>

      <div className="flex-grow mr-4">
        <Link to={`/en/products/${name.replace(/\s+/g, '-')}`}>
          <h4 className="text-sm font-bold text-primary">
            {name} - {size}
          </h4>
        </Link>
      </div>
      <div className="text-right">
        <p className="text-sm font-medium text-primary">{formattedLineTotal}</p>
      </div>
    </div>
  );
}

export default CheckoutItem;
