import React from 'react';
import { useCart } from 'react-use-cart';
import { Link } from 'gatsby';
import { CheckoutProvider } from '../context/Checkout';
import CheckoutForm from '../components/CheckoutForm';
import CheckoutItemList from './CheckoutItemList';
import Error from '../svg/error.svg';
import useSound from 'use-sound';
import Switch from '../sounds/switch.mp3';
import CheckoutButton from './CheckoutButton/CheckoutButton';
import PaypalCheckoutButton from './CheckoutButton/PaypalCheckoutButton';

function Checkout() {
  const [pop] = useSound(Switch);

  const { isEmpty } = useCart();

  //add cartTotal into ^^^
  // const freeDeliveryBar = (cartTotal) => {
  //   if (cartTotal >= 80) {
  //     return null;
  //   } else {
  //     return (
  //       <div className="w-full bg-primary">
  //         <p className="py-1 text-sm text-center text-white text-mono">
  //           spend £80 or more for free delivery
  //         </p>
  //       </div>
  //     );
  //   }
  // };

  if (isEmpty)
    return (
      <div>
        <div className="mb-3">
          <div className="flex flex-col items-center py-8">
            <Error className="w-5/6 lg:4/5" />
          </div>
          <h1 className="mb-3 text-4xl font-bold text-center md:text-5xl text-primary">
            uh oh
          </h1>
        </div>
        <p className="w-4/6 py-4 pb-16 m-auto text-base text-center md:w-3/6">
          theres nothing to see here, how embarrassing. <br />
          not for us.. for you.
          <br />
          <br />
          shall we go home now?
        </p>
        <div className="flex flex-col justify-center mx-16 my-16 lg:mx-64">
          <Link className="secondary-btn" to="/" onClick={pop}>
            <b>We shall, take me home</b>
          </Link>
        </div>
      </div>
    );

  return (
    <CheckoutProvider>
      <div className="lg:flex">
        <div className="order-last lg:w-2/5 lg:-ml-px">
          <div className="pt-8 lg:sticky lg:top-0">
            <CheckoutItemList />
            {/* {freeDeliveryBar(cartTotal)} */}
          </div>
        </div>
        <div className="order-first pt-0 -mt-px lg:w-3/5 sm:mt-0 lg:pt-8">
          <div
            className="h-64 -mb-px border border-primary"
            style={{ minHeight: '350px' }}
          >
            <h3 className="p-8 text-3xl font-bold text-primary md:text-4xl">
              quick pay
            </h3>
            <div className="px-8">
              <div style={{ maxWidth: '600px', margin: '24px auto' }}>
                <CheckoutButton />
              </div>
              <div style={{ maxWidth: '600px', margin: '24px auto' }}>
                <PaypalCheckoutButton />
              </div>
            </div>
          </div>
          <CheckoutForm />
        </div>
      </div>
    </CheckoutProvider>
  );
}

export default Checkout;
