import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';

function Input({ disabled, errors = {}, register, name, ...rest }) {
  const inputClass = classNames(
    'appearance-none border-t border-b border-primary bg-white px-8 py-5 focus:outline-none focus:bg-white text-primary w-full',
    {
      'cursor-not-allowed opacity-50': disabled,
    }
  );

  return (
    <React.Fragment>
      <input
        name={name}
        ref={register}
        className={inputClass}
        disabled={disabled}
        {...rest}
      />
      <ErrorMessage
        as={<p className="mt-2 text-sm text-red" />}
        name={name}
        errors={errors}
        message="fill this in"
      />
    </React.Fragment>
  );
}

export default Input;
